import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import type { IMultiSelectOption } from '@frontend/pole-ui/lib/components/MultiSelect'
import Cookies from 'js-cookie'
import { ViewByOptionsQueryParamsOptions, ViewByOptionsQueryParamsProcessingSpeed } from '@/api/kubik/credit'
import { EUserRegionStorageCookieNames } from '@/logic/userRegion'
import { EFinancingCategory } from '@/pages/Financing/constants'
import { reducerManager } from '@/redux/reducerManager'
import { WithFinancingProductsTab } from './components/WithFinancingProductsTab'
import { messages } from './messages'

const regionCodeFromCookies = Number(Cookies.get(EUserRegionStorageCookieNames.REGION_ID))

export const FINANCING_TABS = [EFinancingCategory.LOANS, EFinancingCategory.LEASING]

export const formKnownValues = new Set(['regionCode', 'fundingPurpose', 'requiredAmount', 'loanPeriod', 'options', 'processingSpeed'])

enum ELoanPurposeForFilter {
  PURCHASE_OF_MACHINERY_EQUIPMENT = 'PURCHASE_OF_MACHINERY_EQUIPMENT',
  WORKING_CAPITAL_TOP_UP = 'WORKING_CAPITAL_TOP_UP',
  PURCHASE_OF_INVENTORY = 'PURCHASE_OF_INVENTORY',
  REFINANCING_OF_CURRENT_LOANS = 'REFINANCING_OF_CURRENT_LOANS',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  OTHER_PURPOSES = 'OTHER_PURPOSES',
}

export const LoanLabelComponent = WithFinancingProductsTab({
  iconName: EIconName.Sell,
  text: messages.tabs.loans,
})

export const LeasingLabelComponent = WithFinancingProductsTab({
  iconName: EIconName.Delivery,
  text: messages.tabs.leasing,
})

export const PURPOSES: Readonly<Record<ELoanPurposeForFilter, string>> = {
  [ELoanPurposeForFilter.PURCHASE_OF_MACHINERY_EQUIPMENT]: messages.purposeOption.purchaseOfMachineryEquipment,
  [ELoanPurposeForFilter.WORKING_CAPITAL_TOP_UP]: messages.purposeOption.replenishmentOfWorkingCapital,
  [ELoanPurposeForFilter.PURCHASE_OF_INVENTORY]: messages.purposeOption.purchaseOfGoodsAndMaterials,
  [ELoanPurposeForFilter.REFINANCING_OF_CURRENT_LOANS]: messages.purposeOption.refinancingCurrentLoans,
  [ELoanPurposeForFilter.BUSINESS_DEVELOPMENT]: messages.purposeOption.businessDevelopment,
  [ELoanPurposeForFilter.OTHER_PURPOSES]: messages.purposeOption.other,
}

export const SPEED_OPTIONS = [
  {
    label: messages.speedOption.online,
    value: ViewByOptionsQueryParamsProcessingSpeed.ONLINE,
  },
  {
    label: messages.speedOption.week,
    value: ViewByOptionsQueryParamsProcessingSpeed.UP_TO_SEVEN_DAYS,
  },
]
export const CREDIT_OPTIONS = [
  {
    label: messages.creditOption.withoutMonthlyPayments,
    value: ViewByOptionsQueryParamsOptions.WITHOUT_MONTHLY_PAYMENTS,
    tooltip: 'Сумма кредита и процентов погашаются единым платежем в конце срока (например, через 12 мес.)',
  },
  {
    label: messages.creditOption.earlyRepayment,
    value: ViewByOptionsQueryParamsOptions.EARLY_REPAYMENT,
  },
  {
    label: messages.creditOption.harvestRepayment,
    value: ViewByOptionsQueryParamsOptions.CROP_REPAYMENT,
    tooltip: 'Рассчитаться по кредиту можно своим урожаем пшеницы, вместо денег',
  },
]

export const PURPOSE_OPTIONS: IMultiSelectOption[] = Object.entries(PURPOSES).map(([value, label]) => ({
  value,
  label,
}))

export const SUM_SLIDER_CONFIG = {
  minValue: 100_000,
  maxValue: 300_000_000,
  step: 100_000,
}

export const TERM_SLIDER_CONFIG = {
  minValue: 1,
  maxValue: 180,
  step: 1,
}

export const DEFAULT_FORM_VALUES = {
  regionCode: reducerManager.store?.getState().userRegion.region.code ?? regionCodeFromCookies,
  fundingPurpose: [],
  requiredAmount: SUM_SLIDER_CONFIG.minValue,
  loanPeriod: TERM_SLIDER_CONFIG.minValue,
  options: [],
  processingSpeed: '',
}
