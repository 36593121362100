import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { LeasingPartnersShortLogos } from '@/components/composed'
import { openModal } from '@/components/composed/Modal/actions'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { pushGtmEventOnClickOrder } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { EFinancingProductId } from '@/types'
import { MultipleLeasingFormModal } from './components'
import { messages } from './messages'
import './styles.scss'

addModalToWellKnown({ MultipleLeasingFormModal })

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const MultipleLeasing: React.FC = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { isAgrarian, userGtmInfo, userRegion } = useSelector(mapState)
  const { isAuthenticated } = useAuthenticateUser()
  const dispatch = useDispatch()

  const openLeasingFormModal = () => {
    setIsModalOpened(true)

    dispatch(
      openModal({
        options: {
          dialogId: 'MultipleLeasingFormModal',
        },
      }),
    )
  }

  useEffect(() => {
    if (isModalOpened && isAuthenticated) {
      // Окно авторизации закрывает открывающуюся модалку с формой
      // Таймаут нужен, чтобы избежать этого поведения
      // TODO: IFA-584
      setTimeout(() => {
        openLeasingFormModal()
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const isDisabled = isAuthenticated && !isAgrarian

  const onClickApply = () => {
    if (isDisabled) {
      return
    }

    openLeasingFormModal()
    pushGtmEventOnClickOrder(userGtmInfo, userRegion.name, EFinancingProductId.MULTI_LEASING)
  }

  return (
    <div className={classNames('multipleLeasing', 'elevatableBlock', { multipleLeasing_clickable: !isDisabled })} onClick={onClickApply}>
      <LeasingPartnersShortLogos className="multipleLeasing__icons" />

      <h3 className="multipleLeasing__title">{messages.title}</h3>

      <div className="multipleLeasing__text">
        <span>{messages.description}</span>
      </div>

      <Button className="multipleLeasing__button" modifiers={['green', 'full-width-mobile']} isDisabled={isDisabled}>
        {messages.button}
      </Button>
    </div>
  )
}
