import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Параметры',
  placeholder: {
    region: 'В каком регионе доступно',
    purpose: 'Цель финансирования',
    sum: 'Желаемая сумма',
    term: 'Срок',
    speed: 'Скорость выдачи',
  },
  purposeOption: {
    purchaseOfMachineryEquipment: 'Покупка техники/оборудования',
    replenishmentOfWorkingCapital: 'Пополнение оборотных средств',
    purchaseOfGoodsAndMaterials: 'Приобретение ТМЦ',
    refinancingCurrentLoans: 'Рефинансирование текущих кредитов',
    businessDevelopment: 'Развитие бизнеса',
    other: 'Иные цели',
  },
  shortMonth: 'мес.',
  speedOption: {
    online: 'Онлайн',
    week: 'До 7 рабочих дней',
    month: 'До 1 месяца',
  },
  creditOption: {
    withoutMonthlyPayments: 'Без ежемесячных платежей',
    earlyRepayment: 'Досрочное погашение без штрафов',
    harvestRepayment: 'Погашение урожаем',
  },
  tabs: {
    loans: 'Кредиты',
    leasing: 'Лизинг',
  },
  reset: 'Сбросить фильтры',
  uom: '₽',
} satisfies TMessages
