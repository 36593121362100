import { IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN } from '@/constants/financing'
import { EFinancingOfferRoute, EFinancingProductId } from '@/types'
import { messages } from './messages'
import type { ILeasingOffer } from './types'

export const LEASING_OFFERS: Readonly<ILeasingOffer[]> = [
  {
    type: 'sberLeasing',
    productId: EFinancingProductId.SBER_LEASING,
    image: {
      source: 'sber-leasing',
      width: 240,
    },
    url: EFinancingOfferRoute.sberLeasing,
    benefits: [messages.products.sberLeasing.benefit.sale],
    detail: {
      value: messages.products.sberLeasing.detail.value,
      label: messages.products.sberLeasing.detail.label,
    },
  },
  {
    type: 'stoneXxiLeasing',
    productId: EFinancingProductId.LEASING_STONE_XXI,
    image: {
      source: 'stone-xxi-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.stoneXxiLeasing,
    benefits: [messages.products.stoneXxiLeasing.benefit.sale],
    detail: {
      value: messages.products.stoneXxiLeasing.detail.value,
      label: messages.products.stoneXxiLeasing.detail.label,
    },
    isHidden: IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN,
  },
  {
    type: 'interleasingLeasing',
    productId: EFinancingProductId.LEASING_INTERLEASING,
    image: {
      source: 'interleasing-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.interleasing,
    benefits: [messages.products.interleasingLeasing.benefit.sale],
    detail: {
      value: messages.products.interleasingLeasing.detail.value,
      label: messages.products.interleasingLeasing.detail.label,
    },
  },
  {
    type: 'alfaLeasing',
    productId: EFinancingProductId.LEASING_ALFA,
    image: {
      source: 'alfa-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.alfaLeasing,
    benefits: [messages.products.alfaLeasing.benefit.sale],
    detail: {
      value: messages.products.alfaLeasing.detail.value,
      label: messages.products.alfaLeasing.detail.label,
    },
    isHidden: IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN,
  },
  {
    type: 'baltiyskiyLeasing',
    productId: EFinancingProductId.LEASING_BALTIYSKIY,
    image: {
      source: 'baltiyskiy-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.baltiyskiyLeasing,
    benefits: [messages.products.baltiyskiyLeasing.benefit.sale],
    detail: {
      value: messages.products.baltiyskiyLeasing.detail.value,
      label: messages.products.baltiyskiyLeasing.detail.label,
    },
    isHidden: true,
  },
  {
    type: 'vtbLeasing',
    productId: EFinancingProductId.LEASING_VTB,
    image: {
      source: 'vtb-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.transportAndSpecialEquipmentLeasing,
    benefits: [messages.products.vtbLeasing.benefit.sale],
    detail: {
      value: messages.products.vtbLeasing.detail.value,
      label: messages.products.vtbLeasing.detail.label,
    },
    isHidden: true,
  },
  {
    type: 'rosAgroLeasing',
    productId: EFinancingProductId.LEASING_RAL,
    image: {
      source: 'rosagro-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.agriculturalMachinesAndEquipmentLeasing,
    benefits: [messages.products.rosAgroLeasing.benefit.sale],
    detail: {
      value: messages.products.rosAgroLeasing.detail.value,
      label: messages.products.rosAgroLeasing.detail.label,
    },
  },
  {
    type: 'fleetFinanceLeasing',
    productId: EFinancingProductId.LEASING_FLEET_FINANCE,
    image: {
      source: 'fleet-finance-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.fleetFinanceLeasing,
    benefits: [messages.products.fleetFinanceLeasing.benefit.discount],
    detail: {
      value: messages.products.fleetFinanceLeasing.detail.value,
      label: messages.products.fleetFinanceLeasing.detail.label,
    },
  },
]
