import { baseChangeGtmEvent, baseFocusGtmEvent } from '@/logic/metrika/constants'
import type { IFormattedGtmEventPayload } from '@/logic/metrika/interfaces'
import { EFinancingOfferType } from '@/types'
import type { TFieldNames } from './interfaces'

export const ApplyingOfferFormGtmFieldName = {
  loanAmountCredit: `loanAmount.value_${EFinancingOfferType.CREDIT}`,
  phoneCredit: `phone_${EFinancingOfferType.CREDIT}`,
  cityCredit: `city_${EFinancingOfferType.CREDIT}`,
  commentCredit: `comment_${EFinancingOfferType.CREDIT}`,

  phoneLeasingRal: `phone_${EFinancingOfferType.LEASING_RAL}`,
  commentLeasingRal: `comment_${EFinancingOfferType.LEASING_RAL}`,

  phoneAdvance: `phone_${EFinancingOfferType.ADVANCE_FUNDING}`,
  contactPersonPositionAdvance: `contactPersonPosition_${EFinancingOfferType.ADVANCE_FUNDING}`,
  loanAmountAdvance: `loanAmount.value_${EFinancingOfferType.ADVANCE_FUNDING}`,
  cropsAreaAdvance: `cropsArea_${EFinancingOfferType.ADVANCE_FUNDING}`,
  desiredAdvancePaymentDateAdvance: `desiredAdvancePaymentDate_${EFinancingOfferType.ADVANCE_FUNDING}`,
  grossHarvestAdvance: `grossHarvest_${EFinancingOfferType.ADVANCE_FUNDING}`,
  commentAdvance: `comment_${EFinancingOfferType.ADVANCE_FUNDING}`,

  phoneLeasingVTB: `phone_${EFinancingOfferType.LEASING_VTB}`,
  emailLeasingVTB: `email_${EFinancingOfferType.LEASING_VTB}`,
  agreementLeasingVTB: `agreement_${EFinancingOfferType.LEASING_VTB}`,

  fullNameTinkoff: `fullName_${EFinancingOfferType.CREDIT_TINKOFF}`,
  phoneTinkoff: `phone_${EFinancingOfferType.CREDIT_TINKOFF}`,
  organizationInnTinkoff: `organizationInn_${EFinancingOfferType.CREDIT_TINKOFF}`,
  organizationNameTinkoff: `organizationName_${EFinancingOfferType.CREDIT_TINKOFF}`,
  loanAmountTinkoff: `loanAmount.value_${EFinancingOfferType.CREDIT_TINKOFF}`,
} as const

export const gtmFocusEventPayloads: Partial<Record<TFieldNames, IFormattedGtmEventPayload>> = {
  [ApplyingOfferFormGtmFieldName.loanAmountCredit]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusCreditAmount',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneCredit]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusPhoneNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.cityCredit]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusServiceCity',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.commentCredit]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusComments',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneLeasingRal]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusPhoneNumber',
    blockName: 'blockApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.commentLeasingRal]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusComments',
    blockName: 'blockApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneLeasingVTB]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusPhoneNumber',
    blockName: 'blockLeasingApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.emailLeasingVTB]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusEmail',
    blockName: 'blockLeasingApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.fullNameTinkoff]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusFullName',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneTinkoff]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusPhoneNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.organizationInnTinkoff]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusTaxNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.organizationNameTinkoff]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusNameCompany',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.loanAmountTinkoff]: {
    ...baseFocusGtmEvent,
    eventLabel: 'focusСreditAmount',
    blockName: 'blockLoanApplicationForm',
  },
}

export const gtmChangeEventPayloads: Record<TFieldNames, IFormattedGtmEventPayload> = {
  [ApplyingOfferFormGtmFieldName.loanAmountCredit]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputCreditAmount',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneCredit]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPhoneNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.cityCredit]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputServiceCity',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.commentCredit]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputComments',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneLeasingRal]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPhoneNumber',
    blockName: 'blockApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.commentLeasingRal]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputComments',
    blockName: 'blockApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPhoneNumber',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.contactPersonPositionAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPosition',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.loanAmountAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputAdvanceAmount',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.cropsAreaAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputCropsArea',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.desiredAdvancePaymentDateAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputAdvanceDate',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.grossHarvestAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputGrossHarvest',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.commentAdvance]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputComment',
    blockName: 'blockAdvanceForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneLeasingVTB]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPhoneNumber',
    blockName: 'blockLeasingApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.emailLeasingVTB]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputEmail',
    blockName: 'blockLeasingApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.agreementLeasingVTB]: {
    ...baseChangeGtmEvent,
    eventLabel: 'checkboxAgreement',
    blockName: 'blockLeasingApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.fullNameTinkoff]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputFullName',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.phoneTinkoff]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputPhoneNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.organizationInnTinkoff]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputTaxNumber',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.organizationNameTinkoff]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputNameCompany',
    blockName: 'blockLoanApplicationForm',
  },
  [ApplyingOfferFormGtmFieldName.loanAmountTinkoff]: {
    ...baseChangeGtmEvent,
    eventLabel: 'inputСreditAmount',
    blockName: 'blockLoanApplicationForm',
  },
}
