import { useEffect, useMemo, useRef } from 'react'
import { useStore } from 'react-redux'
import classNames from 'classnames'
import { useApplyingOfferFormSteps } from '@/components/composed/landingBlocks/deprecated/LandingApplyingOfferForm/useApplyingOfferFormSteps'
import { openModal } from '@/components/composed/Modal/actions'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { FormValuesFormatter, requestStatusName } from '@/constants/financing'
import { isAuthenticated as isUserAuthenticated } from '@/logic/auth/reducer'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { IApplyingFinancingOfferProps, IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { getProfile } from '@/logic/profile/reducer'
import type { ELoanProductName } from '@/types'
import { EFinancingProductId } from '@/types'
import { formattingMFOFinancingOffer } from '@/utils/formattingMFOFinancingOffer'
import { getApplyingFinancingOfferFormInitialValues } from '@/utils/getApplyingFinancingOfferFormInitialValues'
import type { TOpenApplyingFinancingOfferModalHandler } from '@/utils/hooks/applyingFinancingOffer/types'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import { useRegionsMicrocreditOrganizations } from '@/utils/hooks/applyingFinancingOffer/useRegionsMicrocreditOrganizations'
import { isApplyingOfferRequestBody } from '../typeguard'
import { FINANCING_OFFER_MODAL_CONTAINER_CLASSNAME_BY_OFFER_TYPE, FINANCING_OFFER_MODAL_STEPS_ADAPTER } from './constants'
import { defaultFinancingOfferModalStepsAdapter } from './helpers'
import type { TFormInteractionHandler } from './types'
import './styles.scss'

export const useOpenApplyingFinancingOfferModal = (props: IApplyingFinancingOfferProps) => {
  const { productId, offerType, gtmId } = props

  const { getState } = useStore()
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const { dispatch, setOptions } = useApplyingFinancingOfferForm(props)

  const { isMFOProduct, regionsAvailableExpressMFO } = useRegionsMicrocreditOrganizations(props)
  const steps = useApplyingOfferFormSteps(offerType, isAuthenticated)
  const { pushGtmFormView, pushGtmLoanProductModalAuth, pushGtmFormInteraction } = useGtm()

  const onFormInteraction: TFormInteractionHandler = eventLabel => {
    if (gtmId && eventLabel) pushGtmFormInteraction(gtmId, eventLabel)
  }

  const onSubmitFundingRequest = (
    openOfferModal: TOpenApplyingFinancingOfferModalHandler,
    formValues: IFinancingOfferFormValues,
    productName?: ELoanProductName | EFinancingProductId,
  ) => {
    const body = FormValuesFormatter[offerType]?.(formValues) || formValues

    if (!isApplyingOfferRequestBody(body)) return

    const rootState = getState()
    const isAuthenticatedMode = isUserAuthenticated()(rootState)

    const onBackAfterError = () => {
      setTimeout(() => {
        openOfferModal(productName, formValues)
      })
    }

    if (isMFOProduct) {
      formattingMFOFinancingOffer({
        dispatch,
        onBackAfterError,
        regionsAvailableExpressMFO,
        body,
        isAuthenticated: isAuthenticatedMode,
      })
    } else dispatch(applyFinancingOffer({ body, offerType, onBackAfterError }))

    if (!isAuthenticatedMode) {
      setOptions({
        formValues: {},
      })

      if (productName) pushGtmLoanProductModalAuth(productName)

      openAuthModal({
        shouldSendCodeImmediately: true,
        initialValues: { phone: formValues.phone, email: formValues.email, role: 'user' },
        onCloseDialog: () => setOptions({ formValues, onBackAfterError }),
        onCloseModal: () => {
          dispatch(applyFinancingOffer({ body, offerType, isLost: true }))
        },
        shouldSkipSelectRoleStep: true,
      })
    }
  }

  const formattedSteps = useMemo(
    () => {
      const adapter = FINANCING_OFFER_MODAL_STEPS_ADAPTER[offerType] ?? defaultFinancingOfferModalStepsAdapter
      const offerTypeRelatedSteps = adapter(steps, { dispatch, onFormInteraction })

      return offerTypeRelatedSteps
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps],
  )

  const formattedStepsRef = useRef(formattedSteps)

  useEffect(() => {
    formattedStepsRef.current = formattedSteps
  }, [formattedSteps])

  const openApplyingFinancingOfferModal: TOpenApplyingFinancingOfferModalHandler = (productName, initialValues) => {
    if (productName) pushGtmFormView(productName)

    const rootState = getState()
    const profile = getProfile()(rootState)

    if (productId === EFinancingProductId.SBERPAY) {
      dispatch(openModal({ options: { dialogId: 'FinancingConsultingModal' } }))

      return
    }

    dispatch(
      openModal({
        options: {
          dialogId: 'CustomizedFormModal',
          modalClassName: 'financingOfferModalWrapper',
        },
        contentOptions: {
          className: 'financingOfferModal',
          modalContentClassName: classNames(
            'financingOfferModal__container',
            FINANCING_OFFER_MODAL_CONTAINER_CLASSNAME_BY_OFFER_TYPE[offerType],
          ),
          customizedFormProps: {
            autoFocus: true,
            ignoreValidationForSubmitButton: true,
            onSubmit: (values: IFinancingOfferFormValues) => onSubmitFundingRequest(openApplyingFinancingOfferModal, values, productName),
            initialValues: getApplyingFinancingOfferFormInitialValues({ formValues: initialValues, offerType, productId, profile }),
            steps: formattedStepsRef.current,
            requestStatusName,
            shouldShowRequiringFieldsLabel: false,
          },
        },
      }),
    )
  }

  return { openApplyingFinancingOfferModal }
}
