import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@frontend/pole-ui/lib/components/Button'
import { LeasingPartnersShortLogos } from '@/components/composed'
import { openModal } from '@/components/composed/Modal/actions'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { pushGtmEventOnClickOrder } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { MultipleLeasingFormModal } from '@/pages/Financing/components/FinancingProducts/components/LeasingOffers/components/MultipleLeasing/components'
import type { RootState } from '@/redux/interfaces'
import { EFinancingProductId } from '@/types'
import { messages } from './messages'
import './styles.scss'

addModalToWellKnown({ MultipleLeasingFormModal })

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const MultiLeasingCard: React.FC = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const { isAgrarian, userGtmInfo, userRegion } = useSelector(mapState)
  const { isAuthenticated } = useAuthenticateUser()
  const dispatch = useDispatch()

  const openLeasingFormModal = () => {
    setIsModalOpened(true)

    dispatch(
      openModal({
        options: {
          dialogId: 'MultipleLeasingFormModal',
        },
      }),
    )
  }

  useEffect(() => {
    if (isModalOpened && isAuthenticated) {
      // Окно авторизации закрывает открывающуюся модалку с формой
      // Таймаут нужен, чтобы избежать этого поведения
      // TODO: IFA-584
      setTimeout(() => {
        openLeasingFormModal()
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const isDisabled = isAuthenticated && !isAgrarian

  const onClickOrder = () => {
    if (isDisabled) {
      return
    }

    openLeasingFormModal()
    pushGtmEventOnClickOrder(userGtmInfo, userRegion.name, EFinancingProductId.MULTI_LEASING)
  }

  return (
    <div className="multiLeasingCard">
      <div className="multiLeasingCard__header">
        <div className="multiLeasingCard__content">
          <h4>{messages.title}</h4>
          <div className="multiLeasingCard__description">{messages.description}</div>
        </div>
        <LeasingPartnersShortLogos className="multiLeasingCard__logos" />
      </div>
      <div className="multiLeasingCard__actions">
        <Button modifiers={['green', 'full-width-mobile']} onClick={onClickOrder}>
          {messages.actions.order}
        </Button>
      </div>
    </div>
  )
}
