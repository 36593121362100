import type { FC } from 'react'
import React from 'react'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { BubblingTooltip } from '@/components/ui'
import type { ILoanProductTooltipProps } from './types'
import './styles.scss'

export const LoanProductTooltip: FC<ILoanProductTooltipProps> = ({ text }) => {
  return (
    <BubblingTooltip content={text} tooltipProps={{ className: 'loanProductTooltip__text', placement: 'top-start', allowHTML: true }}>
      <div className="loanProductTooltip__content">
        <Icon name={EIconName.QuestFill} className="loanProductTooltip__icon" />
      </div>
    </BubblingTooltip>
  )
}
