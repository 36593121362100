import type { FC } from 'react'
import React, { memo, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import type { TSkeletons } from '@frontend/pole-ui/lib/components/Skeleton'
import qs from 'qs'
import { Skeleton } from '@/components/ui'
import { ELoanPurpose } from '@/components/ui/fields/LoanPurposeSelect/types'
import type {
  ILoanProductsFilterForm,
  ILoanProductsFilterQueryParams,
} from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsFilter/types'
import { EFinancingCategory, ProductsListContext } from '@/pages/Financing/constants'
import type { EFinancingProductId } from '@/types'
import { ELoanProductName } from '@/types'
import { LOAN_PRODUCTS } from '../../constants'
import { LoanProductsEmpty } from '../LoanProductsEmpty'
import { MultiLeasingCard } from './components/MultiLeasingCard'
import { LoanProduct } from './components'
import { messages } from './messages'
import './styles.scss'

const SKELETONS: TSkeletons = Array(8).fill({ height: 324 })

const LoanProductsList: FC = () => {
  const [activeCollapsingProductId, setActiveCollapsingProductId] = useState<Maybe<EFinancingProductId>>(null)
  const { products, isLoading, productsList: productsListIntersection } = useContext(ProductsListContext)

  const { search: searchQuery } = useLocation()
  const queryParams: ILoanProductsFilterQueryParams = qs.parse(searchQuery.substring(1))
  const activeTab = queryParams.tab ?? EFinancingCategory.LOANS
  const form = useFormContext<ILoanProductsFilterForm>()

  const purpose = form.watch('fundingPurpose')

  const showAdditionalLeasing = activeTab === EFinancingCategory.LOANS && purpose?.[0] === ELoanPurpose.PURCHASE_OF_MACHINERY_EQUIPMENT
  const isLeasing = activeTab === EFinancingCategory.LEASING

  const productsList = activeTab === EFinancingCategory.LOANS ? products.compatibleProducts : products.leasingProducts

  return (
    <div className="loanProductsList" ref={productsListIntersection?.blockRef}>
      <div className="loanProductsList__containerOfProducts">
        <Skeleton skeletons={SKELETONS} isLoading={isLoading}>
          {productsList.length < 1 ? (
            <LoanProductsEmpty />
          ) : (
            <>
              {productsList.map(
                product =>
                  !LOAN_PRODUCTS[product.productId]?.isHidden && (
                    <LoanProduct
                      product={product}
                      key={product.productId}
                      activeCollapsingProductId={activeCollapsingProductId}
                      setActiveCollapsingProductId={setActiveCollapsingProductId}
                      isAccentCard={product.productId === ELoanProductName.EXPRESS_MFO}
                      isLeasing={isLeasing}
                    />
                  ),
              )}
              {showAdditionalLeasing && (
                <div className="loanProductsList__additionalLeasing">
                  <h3>{messages.leasingMessage}</h3>
                  <div className="loanProductsList__containerOfProducts">
                    {products.leasingProducts.map(
                      product =>
                        !LOAN_PRODUCTS[product.productId]?.isHidden && (
                          <LoanProduct
                            product={product}
                            key={product.productId}
                            activeCollapsingProductId={activeCollapsingProductId}
                            setActiveCollapsingProductId={setActiveCollapsingProductId}
                            isAccentCard={product.productId === ELoanProductName.EXPRESS_MFO}
                            isLeasing
                          />
                        ),
                    )}
                  </div>
                </div>
              )}
              {activeTab === EFinancingCategory.LEASING && <MultiLeasingCard />}
            </>
          )}
        </Skeleton>
      </div>
    </div>
  )
}

export default memo(LoanProductsList)
