import type { FC } from 'react'
import React, { memo } from 'react'
import type { ILoanProductConditionsProps } from './types'
import './styles.scss'

const LoanProductConditions: FC<ILoanProductConditionsProps> = props => {
  const { conditions } = props

  return (
    <div className="loanProductConditions">
      {conditions.map((condition, index) => (
        <div className="loanProductCondition" key={index}>
          <label className="text_super-small loanProductCondition__label">{condition.name}</label>

          <p className="h5 loanProductCondition__value">{condition.value}</p>
        </div>
      ))}
    </div>
  )
}

export default memo(LoanProductConditions)
