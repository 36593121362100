import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, GlassMorphIcon } from '@/components/ui'
import { DEFAULT_FORM_VALUES } from '../LoanProductsFilter/constants'
import { messages } from './messages'
import './styles.scss'

export const LoanProductsEmpty: React.FC = () => {
  const { reset } = useFormContext()

  const resetFilter = () => {
    reset(DEFAULT_FORM_VALUES)
  }

  return (
    <div className="loanProductsEmpty">
      <GlassMorphIcon image="attention-red" className="loanProductsEmpty__icon" />
      <h4>{messages.title}</h4>
      <ul className="loanProductsEmpty__steps">
        <li>{messages.steps.item1}</li>
        <li>
          {messages.steps.item2}{' '}
          <a className="link color_green underline" href={`tel:${messages.phone.url}`}>
            {messages.phone.label}
          </a>
        </li>
      </ul>
      <div className="loanProductsEmpty__actions">
        <Button onClick={resetFilter} modifiers={['outline-gray', 'small']}>
          {messages.actions.reset}
        </Button>
      </div>
    </div>
  )
}
