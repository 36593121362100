import type { FC } from 'react'
import React, { useState } from 'react'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { messages } from './messages'
import './styles.scss'

export const HiddenArea: FC = ({ children }) => {
  const [hidden, setHidden] = useState(true)
  const { isTablet, isMobile } = useDeviceType()

  const text = hidden ? messages.allFilters : messages.hide

  const toggle = () => {
    setHidden(!hidden)
  }

  if (isTablet || isMobile) {
    return (
      <>
        {!hidden && children}
        <p className="loanProductsHiddenArea__text" onClick={toggle}>
          {text}
        </p>
      </>
    )
  }

  return <>{children}</>
}
