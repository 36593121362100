import type { FC } from 'react'
import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Button } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { injectMessages } from '@/logic/translation/utils'
import messages from './messages.json'
import type { INoSuitableProductsProps } from './types'
import './styles.scss'

injectMessages(messages)

const NoSuitableProducts: FC<INoSuitableProductsProps> = props => {
  const { onResetFilters } = props

  const { formatMessage } = useIntl()
  const { pushGtmFilter } = useGtm()

  const onClick = () => {
    pushGtmFilter(EGtmLabel.RESET_FILTER)
    onResetFilters()
  }

  return (
    <div className="noSuitableProducts">
      <Icon name={EIconName.EmptyState} size={EIconSize.L} className="noSuitableProducts__icon" />
      <div>
        <h4>{formatMessage({ id: 'loanProducts.empty.title' })}</h4>
        {formatMessage({ id: 'loanProducts.empty.description' })}
      </div>
      <Button modifiers={['outline', 'small', 'full-width-mobile']} onClick={onClick}>
        {formatMessage({ id: 'loanProducts.empty.button' })}
      </Button>
    </div>
  )
}

export default memo(NoSuitableProducts)
