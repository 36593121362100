import type { TMessages } from '@/types/TMessages'

export const messages = {
  installment: 'Товары в рассрочку',
  apply: 'Подать заявку на кредит',
  consult: 'Узнать детали',
  more: 'Подробнее',
  calcOnline: 'Рассчитать онлайн',
  leasingMessage: 'Подать заявку',
} satisfies TMessages
