import type { FC } from 'react'
import React, { memo, useContext } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Image } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { LoanProductTooltip } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/components/LoanProductTooltip'
import { ProductInfoContext } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsList/components/LoanProduct/constants'
import { useWebpExtension } from '@/utils/hooks/image'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { ILoanProductHeaderProps } from './types'
import './styles.scss'

const LoanProductHeader: FC<ILoanProductHeaderProps> = props => {
  const { isShowFarmerChoice = false, title } = props
  const { isMobile } = useDeviceType()

  const imageExt = useWebpExtension()

  const { pushGtmProduct } = useGtm()

  const productInfo = useContext(ProductInfoContext)

  if (!productInfo) {
    return null
  }

  const { url, hasNoHeaderLink, gtmId, brand, tooltip } = productInfo!
  const brandLogoSrc = `${isMobile ? `${brand.logo}Mobile` : brand.logo}.${imageExt}`

  const onClickTitle = () => pushGtmProduct(gtmId, EGtmLabel.TITLE)
  const onClickLogo = () => pushGtmProduct(gtmId, EGtmLabel.LOGO)

  return (
    <div className="loanProductHeader">
      <h4 className={classNames('loanProductHeader__title', { 'loanProductHeader__title--withBadge': isShowFarmerChoice })}>
        {url && !hasNoHeaderLink ? (
          <CommonLink url={url} onClick={onClickTitle}>
            {title}
          </CommonLink>
        ) : (
          title
        )}
      </h4>

      <div className="loanProductHeader__logo">
        <CommonLink url={url} onClick={onClickLogo}>
          <Image className="loanProductHeader__brand" isInStatic src={brandLogoSrc} />
        </CommonLink>

        {tooltip && !isMobile && <LoanProductTooltip text={tooltip} />}
      </div>
    </div>
  )
}

export default memo(LoanProductHeader)
