import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Кредитный калькулятор',
  form: { size: 'Предполагаемый размер займа', term: 'Срок займа', formatDay: 'день' },
  psSymbol: '*',
  ps: 'Предложение носит информационный характер и не является публичной офертой по ст.437 (2) ГК РФ. Все приведенные расчеты являются предварительными. Для получения точных условий обратитесь, пожалуйста, к менеджерам.',
  result: {
    title: 'Наше предложение',
    sum: 'Сумма кредита',
    percent: 'Проценты',
    amountRefunded: 'Сумма к возврату',
    actions: {
      sentOrder: 'Подать заявку',
      requestConsult: 'Консультация',
    },
    error: {
      min: 'Минимальная сумма кредита - {minLoanAmount}\u00A0руб. Пожалуйста, попробуйте рассчитать кредит на другую сумму',
      max: 'Максимальная сумма кредита - {maxLoanAmount}\u00A0руб. Пожалуйста, попробуйте рассчитать кредит на другую сумму',
      common: 'Пожалуйста попробуйте рассчитать кредит с другими параметрами.',
    },
  },
} satisfies TMessages
