import type { TMessages } from '@/types/TMessages'

export const messages = {
  products: {
    sberLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 0%',
      },
      benefit: {
        sale: 'Новая и б/у (до 3 лет) сельскохозяйственная техника любого типа с программами субсидий и скидкой 50% на первые 3 платежа',
      },
    },
    stoneXxiLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 5%',
      },
      benefit: {
        sale: 'Лизинг новой и б/у техники из любых сегментов, сезонный график платежей, а также скидка до 15% по программе субсидирования по ППРФ 1432',
      },
    },
    interleasingLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 5%',
      },
      benefit: {
        sale: 'Скидка до 15% по программе субсидирования по ППРФ 1432, а также сезонный график платежей по программе «Плати раз в год»',
      },
    },
    alfaLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 5%',
      },
      benefit: {
        sale: 'Субсидированная программа на новую технику из Беларуси, а также скидки до 20% от дилеров и производителей',
      },
    },
    baltiyskiyLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 0%',
      },
      benefit: {
        sale: 'Программа «Агро рулит» для новой и б/у техники из любых сегментов',
      },
    },
    vtbLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 7%',
      },
      benefit: {
        sale: 'Индивидуальная программа страхования и ежемесячный платёж от 9 тыс. ₽',
      },
    },
    rosAgroLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 0%',
      },
      benefit: {
        sale: 'Более 18 000 позиций как отечественной, так и импортной сельхозтехники и оборудования',
      },
    },
    fleetFinanceLeasing: {
      detail: {
        label: 'Аванс',
        value: 'от 0%',
      },
      benefit: {
        discount:
          'Новая и б/у (до 7 лет) сельскохозяйственная техника любого типа. Платеж 1\u00A0раз в год / отсрочка платежа на 4\u00A0месяца, возможно одобрение за 1\u00A0день',
      },
    },
  },
} satisfies TMessages
