import type { FC } from 'react'
import React, { memo } from 'react'
import { Breadcrumbs } from '@/components/ui'
import { FINANCING_BREADCRUMBS } from '@/pages/Financing/constants'
import './styles.scss'

const FinancingBreadcrumbs: FC = () => (
  <div className="financingBreadcrumbs">
    <Breadcrumbs className="text_super-small-sm" customBreadcrumbs={FINANCING_BREADCRUMBS} />
  </div>
)

export default memo(FinancingBreadcrumbs)
