import React from 'react'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import type { ILoanProductAdditionalValuesProps } from './types'
import './styles.scss'

export const LoanProductAdditionalValues: React.FC<ILoanProductAdditionalValuesProps> = props => {
  const { additionalValues } = props

  if (!additionalValues.length) return null

  return (
    <div className="loanProductAdditionalValues">
      {additionalValues.map((value, index) => (
        <div className="loanProductAdditionalValue" key={index}>
          <Icon className="loanProductAdditionalValue__icon" name={EIconName.OkFill} size={EIconSize.S} />
          <div className="text_super-small">{value}</div>
        </div>
      ))}
    </div>
  )
}
