import { MAX_LOAN_AMOUNT, MIN_LOAN_AMOUNT } from './constants'
import { messages } from './messages'

export const declension = (num: number) => {
  const pr = new Intl.PluralRules('ru-RU')

  const forms: Record<string, string> = {
    one: 'день',
    few: 'дня',
    many: 'дней',
    other: 'дней',
  }
  const rule = pr.select(num) as string

  return `${num} ${forms[rule]}`
}

export const getErrorMessageBySumValue = (sum: number): string => {
  if (sum < MIN_LOAN_AMOUNT) {
    return messages.result.error.min
  }

  if (sum > MAX_LOAN_AMOUNT) {
    return messages.result.error.max
  }

  return messages.result.error.common
}
