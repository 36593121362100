import type { ICustomizedFormStepProps, TCustomizedFormStepField } from '@/components/composed/forms/CustomizedForms/interfaces'
import type { IHandler } from '@/components/composed/landingBlocks/deprecated/LandingApplyingOfferForm/interfaces'
import { ApplyingOfferFormSteps } from '@/constants/financing'
import asyncEpics from '@/logic/financing/epics'
import type { EFinancingOfferType } from '@/types'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { OFFER_TYPES_WITH_DISABLED_FIELDS_WHEN_USER_AUTH } from './constants'
import type { IGetStepsWithHandlers, IHandlers } from './types'

addNewEpicsLazily([asyncEpics])

const injectHandlers = (handlers: IHandler[], field: TCustomizedFormStepField) => {
  return handlers?.reduce((acc, { handlerName, handler }) => ({ ...acc, [handlerName]: handler }), field)
}

const disableStepFields = (steps: ICustomizedFormStepProps[]) =>
  steps.map(step => ({
    ...step,
    fields: step.fields.map(field => ({ ...field, isDisabled: field?.isDisabled !== undefined })),
  }))

const getHandlersByName = (fieldName: string = '', offerType: EFinancingOfferType, handlers: IHandlers) =>
  handlers[`${fieldName}_${offerType}`]

export const getStepsWithHandlers = ({ offerType, isAuthenticated, handlers }: IGetStepsWithHandlers): ICustomizedFormStepProps[] => {
  let steps = ApplyingOfferFormSteps[offerType]
  const firstStep = steps?.[0]
  if (!steps || !firstStep) return []

  if (isAuthenticated) {
    steps = OFFER_TYPES_WITH_DISABLED_FIELDS_WHEN_USER_AUTH.includes(offerType) ? disableStepFields(steps) : steps

    return steps.map(step => ({
      ...step,
      fields: step.fields.map(field => {
        const handlersByName = getHandlersByName(field.name, offerType, handlers)

        return handlersByName ? injectHandlers(handlersByName, field) : field
      }),
    }))
  }

  return [
    {
      ...firstStep,
      fields: firstStep.fields.map(field => {
        const handlersByName = getHandlersByName(field.name, offerType, handlers)
        const fieldResult = handlersByName ? injectHandlers(handlersByName, field) : field

        return field.name === 'phone' ? { ...fieldResult, isDisabled: false } : fieldResult
      }),
    },
  ]
}
