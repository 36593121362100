export const MIN_LOAN_AMOUNT = 300_000
export const MAX_LOAN_AMOUNT = 200_000_000

export const RANGES = {
  sum: {
    minValue: MIN_LOAN_AMOUNT,
    maxValue: MAX_LOAN_AMOUNT,
    step: 10_000,
  },
  days: {
    minValue: 1,
    maxValue: 365,
    step: 1,
  },
}

export const INITIAL_FORM_VALUES = {
  sum: 5_000_000,
  days: 180,
}

export const DEBOUNCE_DELAY = 400
export const COLORS = ['#19A953', '#F6A810']
