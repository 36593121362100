import type { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import type { EAgriculturalProductsRoute, EFinancingOfferRoute, EFinancingOfferType, EFinancingProductId, ELoanProductName } from '@/types'

export enum EConditionLabel {
  LIMIT = 'limit',
  LOAN_AMOUNT = 'loanAmount',
  FROM_5MLN_SECURED = 'from5MlnSecured',
  INSTALLMENT_PERIOD = 'installmentPeriod',
  FREE_PERIOD = 'freePeriod',
  FUNDS_ISSUE = 'fundsIssue',
  LOAN_PERIOD = 'loanPeriod',
  LOAN_PERIOD_V2 = 'loanPeriodV2',
  REVIEW_PERIOD = 'reviewPeriod',
  SOFT_LOANS = 'softLoans',
  COMMERCIAL_LOANS = 'commercialLoans',
  CULTURES = 'cultures',
  AGE_OF_BUSINESS = 'ageOfBusiness',
  INSTALLMENT_AMOUNT = 'installmentAmount',
  OVERPAYMENT = 'overpayment',
  REGISTRATION_TIME = 'registrationTime',
  REPAYMENT_PROCEDURE = 'repaymentProcedure',
}

export enum EConditionValue {
  UP_TO_5MLN_UNSECURED = 'upTo5MlnUnsecured',
  UP_TO_5MLN = 'upTo5Mln',
  UP_TO_10MLN = 'upTo10Mln',
  UP_TO_25MLN = 'upTo25Mln',
  UP_TO_50MLN = 'upTo50Mln',
  UP_TO_1_2MLN = 'upTo1_2Mln',
  UP_TO_200MLN = 'upTo200Mln',
  UP_TO_250MLN = 'upTo250Mln',
  ONE_DAY = 'oneDay',
  ABOUT_3WD = 'about3WorkingDays',
  UP_TO_5WD = 'upTo5WorkingDays',
  UP_TO_30D = 'upTo30Days',
  UP_TO_12M = 'upTo12Months',
  UP_TO_36M = 'upTo36Months',
  UP_TO_2Y = 'upTo2Years',
  UP_TO_10Y = 'upTo10Years',
  UP_TO_15Y = 'upTo15Years',
  UP_TO_5PCT = 'upTo5Pct',
  UP_TO_7PCT = 'upTo7Pct',
  FROM_13PCT = 'from13Pct',
  FROM_1_1PCT = 'from1_1Pct',
  WHEAT = 'wheat',
  ANY = 'any',
  FROM_TREE_UNTIL_SEVEN = 'fromTreeUntilSeven',
  NO_MONTHLY_PAYMENTS = 'noMonthlyPayments',
}

export enum ELoanProductBrand {
  AGROINTEGRATOR = 'agrointegrator',
  DEMETRA = 'demetra',
  POLE_RF = 'poleRf',
  SBER_BUSINESS = 'sberBusiness',
  ALFA = 'alfa',
  CENTER_INVEST = 'centerInvest',
  MANDARIN = 'mandarin',
  POLE_FINANCE = 'poleFinance',
  INTERLEASING = 'interleasing',
  RAL = 'rosagroleasing',
  FLEET_FINANCE = 'fleetFinanceLeasing',
}

export enum ELoanProductBadgeType {
  DISCOUNT_5PCT = 'discount5Pct',
  HIGH_APPROVAL_RATE = 'highApprovalRate',
  THREE_MINUTES = 'threeMinutes',
  INDUSTRY_SOLUTION = 'industrySolution',
  DISCOUNTED_RATES = 'discountedRates',
  WITHOUT_AUDIT = 'withoutAudit',
  SMALLEST_OVERPAYMENT = 'smallestOverpayment',
}

export interface ILoanProductBadge {
  text: ELoanProductBadgeType
  color?: EChipModifier
}

export enum ELoanProductActionType {
  INSTALLMENT_LINK = 'installmentLink',
  ORDER_MODAL = 'orderModal',
  COLLAPSING_BLOCK = 'collapsingBlock',
}

export interface ILoanProductBrand {
  name: ELoanProductBrand
  logo: string
}

export interface ILoanProduct {
  name: string
  offerType: EFinancingOfferType
  productId: EFinancingProductId
  gtmId: ELoanProductName | EFinancingProductId
  brand: ILoanProductBrand
  url?: EFinancingOfferRoute | EAgriculturalProductsRoute
  hasNoModal?: boolean
  hasNoHeaderLink?: boolean
  isHidden?: boolean
  hasConsulting?: boolean
  tooltip?: string
  actionType: ELoanProductActionType
  isAlwaysActiveButton?: boolean
}

export interface ILoanProductsProps {
  isLoanCalculatorShown?: boolean
}
