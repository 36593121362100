import type { FC } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import Tabs from '@/components/ui/Tabs'
import { TabContent } from '@/components/ui/Tabs/components/'
import { EFinancingCategory } from '@/pages/Financing/constants'
import { useScrollDirection } from '@/utils/hooks'
import { LoanForm } from './components/LoanForm'
import { FINANCING_TABS, LeasingLabelComponent, LoanLabelComponent } from './constants'
import type { ILoanProductsFilterQueryParams } from './types'
import './styles.scss'

export const LoanProductsFilter: FC = () => {
  const { search: searchQuery } = useLocation()
  const queryParams: ILoanProductsFilterQueryParams = qs.parse(searchQuery.substring(1))
  const { scrollClassName } = useScrollDirection({ className: 'header', defaultDirection: 'down', minThrottle: 120 })

  const activeTab = queryParams.tab ?? EFinancingCategory.LOANS

  return (
    <div className={`loanProductsFilter loanProductsFilter_${scrollClassName}`}>
      <Tabs
        overrideActiveTab={activeTab}
        defaultActiveTab={FINANCING_TABS.indexOf(activeTab)}
        tabListClassName="loanProductsFilter__tabList"
        tabItemClassName="loanProductsFilter__tabItem"
      >
        <TabContent title="loans" tabId="loans" LabelComponent={LoanLabelComponent}>
          <LoanForm />
        </TabContent>
        <TabContent title="leasing" tabId="leasing" LabelComponent={LeasingLabelComponent} />
      </Tabs>
    </div>
  )
}
