import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import omit from 'lodash/omit'
import type { ICustomizedFormStepProps } from '@/components/composed/forms/CustomizedForms/interfaces'
import { closeModal } from '@/components/composed/Modal/actions'
import type { IPolicyAgreementProps } from '@/components/ui/fields/PolicyAgreement/types'
import { messages } from './messages'
import type { TFinancingOfferModalStepsAdapter } from './types'

export const defaultFinancingOfferModalStepsAdapter: TFinancingOfferModalStepsAdapter = (steps, { onFormInteraction }) =>
  steps.map(step => ({
    ...step,
    fields: step.fields.map(field => {
      let gtmHandler

      if (field.fieldType === 'regionDropdown') {
        gtmHandler = { onOpen: () => onFormInteraction(field.fieldType) }
      } else if (field.fieldType === 'datePicker') {
        gtmHandler = { onSelect: () => onFormInteraction(field.name) }
      } else {
        gtmHandler = { onFocus: () => onFormInteraction(field.name) }
      }

      const controlledField = { ...field, ...gtmHandler }

      if (field.fieldType === 'checkbox') return omit(controlledField, ['activeControlStyle', 'borderColorStyle', 'markColor'])

      if (field.name === 'loanAmount.value') return { ...controlledField, shouldBeTouchedBeforeError: true }

      if (field.fieldType === 'policyAgreement') {
        const initialField = field as IPolicyAgreementProps

        return {
          ...initialField,
          className: 'financingOfferModal__policyAgreement',
          icon: {
            ...initialField.icon,
            className: 'financingOfferModal__policyAgreementIcon',
          },
          linkToAgreement: {
            ...initialField.linkToAgreement,
            className: 'underline underline_green color_green',
          },
        }
      }

      return controlledField
    }),
    submitButton: {
      ...step.submitButton,
      modifiers: ['green'],
    },
  }))

export const advancePaymentOfferModalStepsAdapter: TFinancingOfferModalStepsAdapter = (steps, context) => {
  const extendedSteps: ICustomizedFormStepProps[] = steps.map(step => {
    return {
      ...step,
      fields: step.fields.map(field => {
        switch (field.fieldType) {
          case 'regionDropdown':
          case 'text': {
            return {
              ...field,
              isHangingError: true,
            }
          }
          case 'infoText': {
            return {
              ...field,
              className: 'financingOfferModal__infoText',
              iconProps: {
                name: EIconName.InfoFill,
                className: 'financingOfferModal__infoIcon',
              },
            }
          }
          default: {
            return field
          }
        }
      }),
      backButton: {
        ...step.backButton,
        className: 'financingOfferModal__backButton',
        type: 'button',
        modifiers: ['outline-gray'],
        children: messages.button.cancel,
        onClick: () => context.dispatch(closeModal()),
      },
      submitButton: {
        ...step.submitButton,
        className: 'financingOfferModal__submitButton',
      },
    }
  })

  return defaultFinancingOfferModalStepsAdapter(extendedSteps, context)
}
