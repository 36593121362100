import type { FC } from 'react'
import React, { memo } from 'react'
import { LoanProductsFilter, LoanProductsList } from './components'
import type { ILoanProductsProps } from './types'
import './styles.scss'

const LoanProducts: FC<ILoanProductsProps> = () => {
  return (
    <div className="loanProducts">
      <LoanProductsFilter />
      <LoanProductsList />
    </div>
  )
}

export default memo(LoanProducts)
