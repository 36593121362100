import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'По выбранным условиям ничего не найдено',
  steps: {
    item1: 'Попробуйте изменить параметры фильтров;',
    item2: 'Или обратитесь в службу поддержи по телефону:',
  },
  phone: {
    url: '88007070385',
    label: '8 800 707-03-85',
  },
  actions: {
    reset: 'Сбросить фильтры',
  },
} satisfies TMessages
