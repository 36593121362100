import type { FC } from 'react'
import React from 'react'
import { LoanProducts } from './components'
import { PRODUCTS_BLOCK_ID } from './constants'
import { messages } from './messages'
import type { IFinancingProductsProps } from './types'
import './styles.scss'

export const FinancingProducts: FC<IFinancingProductsProps> = ({ isLoanCalculatorShown }) => {
  return (
    <>
      <div className="financingProducts__title">
        <h2>{messages.title}</h2>
      </div>
      <div id={PRODUCTS_BLOCK_ID} className="financingProducts">
        <LoanProducts isLoanCalculatorShown={isLoanCalculatorShown} />
      </div>
    </>
  )
}
