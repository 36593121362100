import omit from 'lodash/omit'
import { combineEpics } from 'redux-observable'
import { concat, of, throwError } from 'rxjs'
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators'
import { ERegistrationRequestStatus } from '@/api/constants'
import { isStatusOk } from '@/api/helpers'
import request from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { openModal } from '@/components/composed/Modal/actions'
import { financingOfferFailStatuses, requestStatusName } from '@/constants/financing'
import type { Epic } from '@/logic/interfaces'
import { sendSubmitFormStatusEvent } from '@/logic/metrika/constants'
import { pushGtmEventOnSubmitOrder } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { isSomeEnum } from '@/typeguards/common'
import { ESubmitFormStatusWithGtm } from '@/types'
import { applyFinancingOffer } from './actions'
import type {
  IApplyFinancingOffer,
  IApplyingFinancingOfferResponseData,
  IApplyingFinancingOfferSuccessModalOptions,
  TOpenErrorMessageModalByStatusOptions,
} from './interfaces'
import { messages } from './messages'

const isFinancingOfferFailStatus = isSomeEnum(ERegistrationRequestStatus)

const hasErrorToShowInModal = (status: Maybe<string> | undefined): status is ERegistrationRequestStatus =>
  isFinancingOfferFailStatus(status) && financingOfferFailStatuses.includes(status)

export const openErrorMessageModalByStatus = ({ status, message, onBackAfterError }: TOpenErrorMessageModalByStatusOptions) => {
  const headerByStatus: Partial<Record<string, string>> = {
    [ERegistrationRequestStatus.OKVEDS_NOT_FOUND]: messages.error.paymentOffer.status.OKVEDS_NOT_FOUND,
    [ERegistrationRequestStatus.STATUS_NOT_ACTIVE]: messages.error.paymentOffer.status.STATUS_NOT_ACTIVE,
  }

  const presetHeader = status ? headerByStatus[status] : undefined
  const headerId = presetHeader ?? message

  return openModal({
    options: {
      dialogId: 'MessageModal',
      headerId,
    },
    contentOptions: {
      modalContentClassName: 'financingMessageModal',
      buttons: [
        {
          children: messages.advancePaymentOffer.button.back,
          modifiers: ['full-width', 'green'],
          className: 'space-holder8',
          onClick: onBackAfterError,
        },
      ],
    },
  })
}

const onApplyFinancingOfferDoMakeRequest: Epic<IApplyFinancingOffer, typeof applyFinancingOffer> = (action$, state$) =>
  action$.pipe(
    filter(applyFinancingOffer.match),
    switchMap(({ payload }) => {
      const { body, offerType, isLeasing, isLost, skipSuccessModal, onBackAfterError } = payload

      const { value } = state$
      const { app, userRegion } = value

      const userGtmInfo = getUserGtmInfo()(state$.value)

      const formattedBody = isLeasing ? body : { ...omit(body, ['productId']), productIds: [body.productId] }
      const isMultiLeasing = formattedBody.productIds && formattedBody.productIds.length > 1

      const fundingRequestApiUrl = isLost ? ECommonApiUrl.fundingRequestsLost : ECommonApiUrl.fundingRequests

      return concat(
        of(requestStarted({ name: requestStatusName })),
        request<IApplyingFinancingOfferResponseData[]>({
          url: fundingRequestApiUrl,
          method: 'POST',
          body: isNaN(Number(formattedBody.loanAmount?.value)) ? omit(formattedBody, ['loanAmount']) : formattedBody,
        }).pipe(
          mergeMap(response => {
            const status = response.meta?.status

            if (hasErrorToShowInModal(status)) {
              return throwError(response)
            }

            pushGtmEventOnSubmitOrder(
              userGtmInfo,
              userRegion.region.name,
              response.data,
              isMultiLeasing,
              formattedBody.productIds,
              body.productId,
            )

            if (isStatusOk(response) && !isLost) {
              if (offerType && body.productId)
                sendSubmitFormStatusEvent(body.productId, ESubmitFormStatusWithGtm.success, state$.value, app.location.pathname)

              return of(
                ...[
                  requestFinished({ name: requestStatusName }),
                  !skipSuccessModal &&
                    openModal({
                      options: {
                        dialogId: 'ApplyingFinancingOfferSuccessModal',
                      },
                      contentOptions: {
                        prevFormValues: body,
                        requestId: response.data[0]?.id,
                      } as IApplyingFinancingOfferSuccessModalOptions,
                    }),
                ].filter(Boolean),
              )
            }

            return throwError(response)
          }),
          catchError(({ meta }) => {
            if (hasErrorToShowInModal(meta?.status)) {
              return of(
                requestFinished({ name: requestStatusName }),
                openErrorMessageModalByStatus({
                  status: meta?.status,
                  message: meta?.message,
                  onBackAfterError,
                }),
              )
            }

            if (offerType && body.productId)
              sendSubmitFormStatusEvent(body.productId, `err: ${meta?.message}`, state$.value, app.location.pathname)

            return of(requestFailed({ name: requestStatusName }))
          }),
        ),
      )
    }),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(onApplyFinancingOfferDoMakeRequest)
